var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<main><div class=\"container\"><div class=\"row\"><div class=\"s12 center\"><div class=\"card orange darken-1\"><div class=\"card-content white-text\"><i class=\"material-icons medium\">error</i><p>{{ errorMsg | translate }}</p></div><div class=\"card-action\"><div class=\"row\"><a href=\"#/\" translate=\"err.back_index\" class=\"btn-large orange darken-3 waves-light waves-effect\"></a></div></div></div></div></div></div></main>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}