var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<main class=\"valign-wrapper\"><div class=\"container\"><div class=\"row\"><div class=\"s12 center\"><div class=\"preloader-wrapper active\"><div class=\"spinner-layer spinner-blue-only\"><div class=\"circle-clipper left\"><div class=\"circle\"></div></div></div></div><p>{{ message | translate:'{number: current_number}' }}</p></div></div></div></main>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}